import Vue from 'vue'
import App from './App.vue'


import {Area,Checkbox,Dialog } from 'vant'
import 'vant/lib/index.css'

// const {createApp } = Vue
// const app = createApp()
Vue.use(Area)
Vue.use(Checkbox)
Vue.use(Dialog)

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
}).$mount('#app')
