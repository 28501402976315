<template>
  <div ref="con" :style="agreementStatus || successStatus ? 'overflow: hidden!important;height: 100vh;':''">
    <div class="page">
      <div v-for="(item) in dian.pages" :key="item.id" >
        <div v-if="item.elName == 'yi-page'" :id="item.elName+'_'+item.id"  :style="item.style">
          <div v-for="(childItem,index) in item.elements" :key="index">
            <!-- 图片 start -->
            <div v-if="childItem.elName == 'yi-image'" :style="childItem.style" :id="childItem.elName+'_'+childItem.id" class="quark-element-wrapper yi-image-element-wrapper">
              <div class="yi-image yi-element">
                <img src="http://res.uakak.com/img/standard29_banner.jpg" v-if="childItem.title == 'banner图'" alt="">
                <img src="http://res.uakak.com/img/standard29_tao.jpg" v-else-if="childItem.title == '资费详情图'" alt="">
                <img :src="childItem.imageSrc" v-else alt="">
              </div>
            </div>
            <!-- end 图片 -->

            <!-- 表单 -->
            <div v-if="childItem.elName == 'yi-form'" :id="childItem.elName+'_'+childItem.id"  :style="childItem.style">
              <div class="fill-wrapper yi-form yi-element">
                <div v-for="(child3,index) in childItem.elements" :key="index" :style="child3.style" :id="child3.elName+'_'+child3.id" v-show="['办理人姓名','联系方式'].includes(child3?.elements?.[0].text) || ['提交按钮','协议','(余量/号码)轮播'].includes(child3?.title) || initInfoStatus" class="quark-element-wrapper yi-name-input-element-wrapper"> 

                    <!-- 点击按钮 -->
                    <a  v-if="child3.elName =='yi-button'" :id="child3.id" class="yi-button yi-element ripple-btn" @click="submitHandle">
                      <div class="btn-con img-btn">
                        <img :src="child3.imageSrc" alt="">
                      </div>
                    </a>
                    <!-- 协议 -->
                    <div v-else-if="child3.elName =='yi-conditions'" class="yi-conditions yi-element">
                      <div role="checkbox" tabindex="0" aria-checked="false" class="van-checkbox" style="color: rgb(255, 255, 255);">
                        <!-- <div class="van-checkbox__icon van-checkbox__icon--square" style="font-size: 14px;">
                          <i class="van-icon van-icon-success"></i>
                        </div> -->
                        <van-checkbox v-model="checkedStatus" shape="square" checked-color="#fffbd4" icon-size="14px"></van-checkbox>
                      </div>
                      <div class="a_title">
                        <span class="text">我已阅读并同意</span>
                        <span v-for="(child4,child4Index) in child3.protocolArr" @click="agreemenHandle(child4)" :key="child4Index" class="alertAgreement">{{ child4.title }}</span>
                      </div>
                    </div>
                    <!-- 轮播 -->
                    <div v-else-if="child3.elName =='yi-swipe-tips'" class="block yi-element">
                      <div class="swipe-tips">
                        <!-- singleHeight: 51.83, -->
                        <vue-seamless-scroll :data="child3.elements[1]?.items" class="warp" :class-option="{waitTime:2122,singleHeight: 37.83, hoverStop:false}">
                          <ul class="item">
                            <li v-for="(item4, index) in child3.elements[1]?.items" :key="index" >
                              <div v-html="item4.content"></div>
                            </li>
                          </ul>
                        </vue-seamless-scroll>
                      </div>
                    </div>
                    <!-- 表单 -->
                    <div class="cm-input-item yi-name-input yi-element" 
                      :class="child3.elements[0]?.text == '办理人姓名' && monikerStatus ? 'error' : 
                      child3.elements[0]?.text == '联系方式' && phoneStatus ? 'error' : 
                      child3.elements[0]?.text == '身份号码' && idCardStatus ? 'error' : 
                      child3.elements[0]?.text == '收货地址' && addressInfoStatus ? 'error' : 
                      child3.placeholder?.includes('详细地址') && addressDetailsStatus ? 'error' : ''"
                    v-if="child3.elName !='yi-button'">
                      <!-- <div v-if="child4.elName == 'input-label'" :style="child4.style" :id="child4.elName+'_'+child4.id" quark-element-wrapper yi-name-input-element-wrapper> -->
                        <!-- <span v-for="(child4,index) in child3.elements" :Key="index"> -->
                          <span v-if="child3.elements?.length && child3.elements[0].elName == 'input-label'" class="label require" :style="child3.elements[0].style" :id="child3.elements[0].elName+'_'+child3.elements[0].id">{{ child3.elements[0].text }}</span>
                      <!-- {{  Array.isArray(child3.elements )}}-
                      -->
                      <!-- {{  child3.elements?.length}} -->
                          <template v-if="child3.elements?.length">
                            <div class="yi-input" v-if="child3.elements[1]?.elName == 'input' && child3.elements[0]?.text == '办理人姓名'">
                              <input type="text" id="custName"  maxlength="50" v-model="formData.moniker" :placeholder="child3.placeholder" autocomplete="on" :style="child3.elements[1]?.style">
                            </div>
                            <div class="yi-input" v-else-if="child3.elements[1]?.elName == 'input' && child3.elements[0]?.text == '联系方式'">
                              <input type="text" id="custName" v-model="formData.phone" maxlength="50" :placeholder="child3.placeholder" autocomplete="on" :style="child3.elements[1]?.style">
                            </div>
                            <div class="yi-input" v-else-if="child3.elements[1]?.elName == 'input' && child3.elements[0]?.text == '身份号码'">
                              <input type="text" id="custName" v-model="formData.idCard" maxlength="50" :placeholder="child3.placeholder" autocomplete="on" :style="child3.elements[1]?.style">
                            </div>
                            <template v-else-if="child3.elements[1]?.elName == 'input' && child3.elements[0]?.text == '收货地址'">
                              <div class="yi-input" @click="addressStatus =true">
                                <input type="text" id="custName"  maxlength="50" v-model="formData.addressInfo"  :placeholder="child3.placeholder" autocomplete="on" :style="child3.elements[1]?.style">
                              </div>
                              <div class="cm-guide-tips" @click="addressStatus =true">
                                <span class="select-icon">可更改</span>
                                <i class="arrow-icon van-icon van-icon-arrow"></i>
                              </div>
                              <div class="quark-element-wrapper yi-view-element-wrapper" :style="child3.elements[2].style" :id="child3.elements[2].elName+'_'+child3.elements[2].id"></div>
                            
                              <!-- <van-area title="请选择收货地址" :area-list="areaList" /> -->
                              <!-- <div>
                                <div class="van-overlay" style="z-index: 2019;display: none;">
                                </div>
                                <div class="select-region-modal van-popup van-popup--bottom" style="width: 100%; z-index: 2028;display: none;">
                                  <van-area title="请选择收货地址" :area-list="areaList" />
                                </div>
                              </div> -->
                            </template>
                            
                            <!-- 请输入详细地址 -->
                            <div v-else-if="child3.elName == 'yi-address-input'" class="yi-input input-area" >
                              <input type="text" id="custName" v-model="formData.addressDetails"  maxlength="50" :placeholder="child3.placeholder" autocomplete="on" :style="child3.elements[0]?.style">
                            </div>
                            
                          </template>
                          
                        <!-- </span> -->
                      <!-- </div> -->
                    </div>
                    <!-- addressDetailsIsTips -->
                    <div style="color:#666666;font-size: .32rem;padding: 0.26667rem 0 0.13333rem 0;line-height: .53333rem;" v-if="child3.placeholder?.includes('详细地址') && addressDetailsIsTips">亲~您的地址过于简单，快递小哥可能无法配送哦！</div>

                </div>
                
                
              </div>
            </div>
            <!-- END 表单 -->

            <!-- 其余资费说明 -->
            <div v-if="childItem.elName == 'yi-view'" :id="childItem.elName+'_'+childItem.id"  :style="childItem.style">
              <div class="yi-view yi-element">
                <div v-for="(child2,index) in childItem.elements" :key="index" :style="child2.style" :id="child2.elName+'_'+child2.id"  class="quark-element-wrapper yi-view-element-wrapper">
                  <div class="yi-view yi-element" v-html="child2.elements[0].vhtml" :style="child2.elements[0].style">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="" style="color:#fff;font-size: 0.31rem;text-align: center;margin: 0.5rem 0 0;" v-if="urlParams.cps1=='xxl@dy@zbj' && urlParams.cps2=='xxl@dy@zbj' ">深圳百众联信息技术有限公司</div>
    </div>

    <!-- 隐私协议弹框 start -->
    <div class="van-overlay" style="z-index: 2009;" :style="agreementStatus ? '':'display: none;'"></div>
    <div class="van-popup van-popup--center" style="height: 12.5rem; width: 9rem; background: none; border-radius: 0.133rem; z-index: 2010;" :style="agreementStatus ? '':'display: none;'">
      <div class="popup">
        <div class="titLine">
          <span data-v-3b94020f="">服务协议</span>
          <img data-v-3b94020f="" src="https://static.ejcop.com/octopus/webplus/20211101174513792.png" @click="agreementStatus = false" alt="">
        </div>
        <div class="agreeMain" v-html="agreementInfo">
        </div>
      </div>
    </div>
    <!-- end 隐私协议弹框 -->

    <!-- 收货地址弹框 -->
    <div class="van-overlay" style="z-index: 2019;" :style="addressStatus ? '':'display: none;'"> 
    </div>
    <div class="select-region-modal van-popup van-popup--bottom" style="width: 100%; z-index: 2028;" :style="addressStatus ? '':'display: none;'">
      <van-area title="请选择收货地址" :area-list="areaList" @cancel="addressStatus =false" v-model="formData.addressInfo" @confirm="addressSuccessHandle" />
    </div>

    <!-- 不能为空弹框 -->
		<div class="van-toast van-toast--middle van-toast--fail" style="z-index: 2022;" :style="verifyTipStatus || submitStatus ? '': 'display: none;'">
      <img src="http://res.uakak.com/img/standard29_xua.gif" style="width: 0.743rem;" v-if="submitStatus" />
			<i class="van-icon van-icon-fail van-toast__icon" v-else></i>
			<div class="van-toast__text">{{ tipInfo }}</div>
		</div>

    <!--  -->
    <div class="van-overlay" style="z-index: 2009;" :style="successStatus ? '':'display: none;'"></div>
    <div class="van-popup van-popup--center" style="height: 6.3rem; width: 5.24rem; background: linear-gradient(180deg, #FEECDF 0%, #FFF9F4 100%); border-radius: 0.133rem; z-index: 2010;" :style="successStatus ? '':'display: none;'">
      <img src="http://res.uakak.com/img/standard29_success.png" alt="" style="width: 3.76rem;height: 2.5066rem;margin: 0.32rem auto 0;">
      <img src="http://res.uakak.com/img/success-title.png" style="width: 2.56rem;height: 0.76rem;margin: 0.32rem auto 0.84rem" alt="">
      <div @click="successStatus = false"
        style="width: 3.31481rem;height: 0.92rem;margin: 0px auto;background: #1F1F1F;border-radius: 0.86rem;font-size: 0.32rem;font-weight: 400;color: #ffff;display: flex;justify-content: center;align-items: center;">
        我知道了
      </div>
    </div>

    

  </div>
</template>

<script>
import dian from '../assets/dian.json'
import vueSeamlessScroll from 'vue-seamless-scroll'
import { areaList } from '@vant/area-data'
import axios from 'axios'
// import { Button } from 'vant'
export default {
  name: 'HelloWorld',
  components:{
    vueSeamlessScroll,
    // [Button.name]: Button,
  },
  props: {
    // msg: String
  },
  created(){
    this.urlParams = {}

		if(location.search){
			const data = location.search.substr(1).split('&')
			for(let index in data){
				const [key, val] = data[index].split('=')
				this.urlParams[key] = val
			}
		} 
    if(!localStorage.getItem('uuid')){
      localStorage.setItem('uuid',this.guid())
    }
		console.log('提取参数',this.urlParams,'uuid',localStorage.getItem('uuid'))

    if(this.pageReportingStatus) return
    this.pageReportingStatus = true
    this.reportRequest('page_view')
  },  
  data() {
    return {
      dian,
      areaList,
      agreementInfo:'', //协议内容
      agreementStatus: false, //协议内容弹框状态
      addressStatus: false, //选择地址弹框状态
      // addressInfo:'',
      formData:{},
      conH:0,
      pageReportingStatus: false,//页面上报状态
      clickPageBottonStatus: false,//点击上报状态
      publicUrl:'https://wv.uakak.com',
      axiosUrl:{
        submitUrl:'/promotion/form/submit', // 领取号卡接口地址
        reportEventUrl:'/promotion/report/event' // 页面上报接口地址
      },
      timeUp:'',
      // url:'http://192.168.0.157:8081/promotion/form/submit',
      url:'https://wv.uakak.com/promotion/form/submit',//正式
      checkedStatus:false,
      verifyStatus: false, //校验状态
      verifyTipStatus: false, //验证提示状态
      tipInfo: '', //验证tip失败弹框
      monikerStatus: false,
      phoneStatus:false,
      idCardStatus:false, //身份证验证状态
      addressInfoStatus:false,//收货地址表单状态
      addressDetailsStatus:false, // 收货地址详情
      addressDetailsIsTips:false, // 收货地址详情
      timeout:'',
      submitStatus: false, //提交状态
      submitTimeout:'',
      addressList:[],
      urlParams:{},
      initInfoStatus: false,
      successStatus:false, //领取成功弹框
    }
  },
  methods:{
    agreemenHandle(val){
      console.log('点击协议',val)
      this.agreementInfo = val.vhtml
      this.agreementStatus = true
    },
    addressSuccessHandle(addressList){
      console.log('点击地址',addressList)
      this.addressList = addressList
      this.addressStatus = false
      this.formData.addressInfo =''
      addressList.forEach((item,index) => {
        this.formData.addressInfo += item.name
        if(index === 0) this.formData.receiverProv = item.name
        if(index === 1) this.formData.receiverCity = item.name
        if(index === 2) this.formData.receiverDistrict = item.name
      })
      if(!this.formData.addressInfo){
        this.addressInfoStatus = true
      } else {
        this.addressInfoStatus = false
      }
    },
    submitHandle(){
      if(!this.clickPageBottonStatus){
        this.clickPageBottonStatus = true
        this.reportRequest('click')
      }      
      
      this.verifyStatus = true
      const than = this
      
      var reg = /^[\u4e00-\u9fa5]+$/ //验证名字中文
      var regPhone = /^1[3,4,5,6,7,8,9][0-9]{9}$/; //验证手机号码
      const regIdCard = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/ //验证身份证号

      const addressDefaReg =  /^\S{7,}$/

      if(!this.formData.moniker){
        this.verifyTipStatus = true
        this.monikerStatus = true
        this.tipInfo = '姓名不能为空'
        setTimeout(() => {
          this.verifyTipStatus = false
        },2000)
      }else if(!reg.test(this.formData.moniker)){
        this.verifyTipStatus = true
        this.monikerStatus = true
        this.tipInfo = '输入姓名必须为有效中文！'
        setTimeout(() => {
          this.verifyTipStatus = false
        },2000)
      } else if(!this.formData.phone){
        this.verifyTipStatus = true
        this.phoneStatus = true
        this.tipInfo = '手机号不能为空'
        setTimeout(() => {
          this.verifyTipStatus = false
        },2000)
      }else if(!regPhone.test(this.formData.phone)){
        this.verifyTipStatus = true
        this.phoneStatus = true
        this.tipInfo = '手机号号格式错误，请重新检查'
        setTimeout(() => {
          this.verifyTipStatus = false
        },2000)
      } else if(!this.formData.idCard){
        this.verifyTipStatus = true
        this.idCardStatus = true
        this.tipInfo = '请输入身份证件号'
        setTimeout(() => {
          this.verifyTipStatus = false
        },2000)
      }else if(!regIdCard.test(this.formData.idCard)){
        this.verifyTipStatus = true
        this.idCardStatus = true
        this.tipInfo = '身份证件号长度有误，请输入18位的身份证件号'
        setTimeout(() => {
          this.verifyTipStatus = false
        },2000)
      } else if(!this.formData.addressInfo){
        this.verifyTipStatus = true
        this.addressInfoStatus = true
        this.tipInfo = '收货地址所在地区不能为空！'
        setTimeout(() => {
          this.verifyTipStatus = false
        },2000)
      } else if(!this.formData.addressDetails){
        this.verifyTipStatus = true
        this.addressDetailsStatus = true
        this.tipInfo = '请输入详细收货地址'
        setTimeout(() => {
          this.verifyTipStatus = false
        },2000)
      }else if(!addressDefaReg.test(this.formData.addressDetails)){
        this.verifyTipStatus = true
        this.addressDetailsStatus = true
        this.addressDetailsIsTips =true
        this.tipInfo = '收货地址长度太短，请输入详细的地址'
        setTimeout(() => {
          this.verifyTipStatus = false
        },2000)
      }else if(addressDefaReg.test(this.formData.addressDetails)){
        this.verifyTipStatus = false
        this.addressDetailsStatus = false
        this.addressDetailsIsTips =false
        
        
        console.log('验证完成')
        this.checkedStatus = true
        
        if(this.submitTimeout) {
          this.tipInfo = "您已提交过该订单了, 请勿重复提交[jz]"
          // this.submitStatus = true
          than.verifyTipStatus = true
          than.submitStatus = false
          setTimeout(() => {
            // this.submitStatus = false
            than.verifyTipStatus = false
          },2000)
        } else {
          this.submitStatus = true
          this.tipInfo = '抢购中'
          this.submitTimeout = true
          // setTimeout(() => {
          //   this.submitStatus = false

            const {moniker:receiverName,phone:mobilePhone,idCard:cartNo,addressDetails:receiverAddress,receiverProv,receiverCity,receiverDistrict} = this.formData
            axios.post(this.url, {
              name:receiverName,
              receiverName,
              mobilePhone,
              cartNo,
              receiverProv,
              receiverCity,
              receiverDistrict,
              receiverAddress,
              productId:'GDSXP202112271135006',
              channel:'10',
              pageUrl: location.href, //页面URL
              ...this.urlParams

            })
            .then(function (res) {
              console.log(res);
              if(res.data.code != '1'){
                than.submitTimeout = false
                than.verifyTipStatus = true
                than.submitStatus = false
                than.tipInfo = res.data.msg
                setTimeout(() => {
                  than.verifyTipStatus = false
                  
                },2000)
              } else {
                // than.verifyTipStatus = true
                // than.submitStatus = false
                // // than.submitTimeout = false
                // // than.verifyTipStatus = false
                // than.tipInfo = res.data.data
                // setTimeout(() => {
                //   than.verifyTipStatus = false
                // },2000)
                than.submitStatus = false
                than.verifyTipStatus = false
                than.successStatus = true
              }
              
            })
            .catch(function () {
              than.submitTimeout = false
                than.verifyTipStatus = true
                than.tipInfo = '领取异常'
                setTimeout(() => {
                  than.verifyTipStatus = false
                  than.submitStatus = false
                },2000)
            });
          // },2000)
        }
        
      }

        
      
    },
    reportRequest(eventType){
      const than = this
      axios.post(this.publicUrl+this.axiosUrl.reportEventUrl, {
        eventId:this.guid(),
        clientId:localStorage.getItem('uuid'),
        pageId:this.urlParams.page_id, //页面ID
        eventType, //事件类型
        pageUrl: location.href, //页面URL
        trcTime:new Date().getTime(), //上报时间
      })
      .then(function (res) {
        console.log(res);
        if(res.data.code != '1'){
          clearTimeout(this.timeUp)
          this.timeUp = null
        }
      }).catch(function () {
        than.timeUp && clearTimeout(than.timeUp)
        than.timeUp = null
      });

    },
    // 监听事件
    scrolling() {
      let current =
        document.documentElement.scrollTop || document.body.scrollTop;
      let vh = window.innerHeight;
      let conH = this.$refs.con?.offsetHeight;


      if(conH - current <= vh + 100){
       if(this.timeUp) return
        this.timeUp = setTimeout(() => {
          console.log('符合上报',this.guid())
          // eventType取值：page_view(进入页面上报), page_bottom（拉到底部上报）, click(点击领取上报)
          this.reportRequest('page_bottom')
        },2000)
      }
      
      // console.log(11111,current,vh,this.$refs.con.offsetHeight)
    },
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = Math.random() * 16 | 0,
              v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
      });
    }
  },
  watch:{
    'formData.moniker'(curVal, oldVal) {
      console.log(curVal, oldVal)
      // 实现input连续输入，只发一次请求
      var reg = /^[\u4e00-\u9fa5]+$/;
      console.log('验证',reg.test(curVal))
      // let isSuc = !reg.test(curVal)
      clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if(!curVal){
            // this.verifyTipStatus = true
            this.monikerStatus = true
          }else if(!reg.test(curVal)){
            this.monikerStatus = true
          } else {
            this.monikerStatus = false
          }
        }, 300);
    },
    'formData.phone'(curVal, oldVal) {
      console.log(curVal, oldVal)
      // 实现input连续输入，只发一次请求
      var regPhone = /^1[3,4,5,6,7,8,9][0-9]{9}$/; //验证手机号码

      console.log('验证',regPhone.test(curVal))
      // let isSuc = !reg.test(curVal)
      clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if(!curVal){
            // this.verifyTipStatus = true
            this.phoneStatus = true
          }else if(!regPhone.test(curVal)){
            this.phoneStatus = true
          } else {
            this.phoneStatus = false
            if(!this.initInfoStatus) this.initInfoStatus = true
          }
        }, 300);
    },
    'formData.idCard'(curVal, oldVal) {
      console.log(curVal, oldVal)
      // 实现input连续输入，只发一次请求
      const regIdCard = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/ //验证身份证号

      console.log('验证',regIdCard.test(curVal))
      // let isSuc = !reg.test(curVal)
      clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if(!curVal){
            // this.verifyTipStatus = true
            this.idCardStatus = true
          }else if(!regIdCard.test(curVal)){
            this.idCardStatus = true
          } else {
            this.idCardStatus = false
          }
        }, 300);
    },
    'formData.addressInfo'(curVal, oldVal) {
      console.log(curVal, oldVal)
      // 实现input连续输入，只发一次请求
    
      clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if(!curVal){
            this.addressInfoStatus = true
          } else {
            this.addressInfoStatus = false
          }
        }, 300);
    },
    'formData.addressDetails'(curVal, oldVal) {
      console.log(curVal, oldVal)
      // 实现input连续输入，只发一次请求

      const addressDefaReg =  /^\S{7,}$/
    
      clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if(!curVal){
            // this.verifyTipStatus = true
            this.addressDetailsStatus = true
          }else if(!addressDefaReg.test(curVal)){
            this.verifyTipStatus = true
            this.addressDetailsStatus = true
            this.addressDetailsIsTips =true
            this.tipInfo = '收货地址长度太短，请输入详细的地址'
          } else {
            this.verifyTipStatus = false
            this.addressDetailsStatus = false
            this.addressDetailsIsTips =false
          }
          
        }, 300);
    }
  },
  mounted(){
    // 添加监听事件
    window.addEventListener("scroll", this.scrolling);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
