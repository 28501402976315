<template>
  <div id="app">
    <dianXing />
    <!-- <lianTong /> -->
  </div>
</template>

<script>
import dianXing from './dianXin/dem1.vue'
// import lianTong from './lianTong/dem1.vue'

export default {
  name: 'App',
  components: {
    dianXing
    // lianTong
  }
}
</script>

<style>

</style>
